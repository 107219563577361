'use client';

import { AxiosError } from 'axios';
import { useTranslations } from 'next-intl';
import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Flags from 'react-phone-number-input/flags';

import { useAuth } from '@/contexts/AuthContext';
import {
  applyCPFMask,
  applyCNPJMask,
  applyPhoneMask,
  cpfValidate,
} from '@/utils/forms';

import Input from '@/components/form/Input';
import Button from '@/components/ui/Button';
import Select from '@/components/form/Select';

import styles from './Register.module.scss';
import ReCAPTCHA from 'react-google-recaptcha';
import { RefObject, useRef } from 'react';
import { Tokens } from '@/config/tokens';
import { E164Number } from 'libphonenumber-js/core';
import Label from '@/components/form/Label';
import Icon from '@/components/ui/Icon';

const passwordSchema = z
  .string()
  .min(8, { message: 'register.password' })
  .refine(
    (password) =>
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        password,
      ),
    {
      message: 'register.password_special_characters',
    },
  );

const registerFormSchema = z
  .object({
    user_type: z.string().min(2, { message: 'register.user_type' }),
    full_name: z.string().min(2, { message: 'register.full_name' }),
    cpf: z.string().refine(cpfValidate, 'register.cpf'),
    phone: z.string().min(2, { message: 'register.phone' }),
    email: z
      .string()
      .email({ message: 'register.email' })
      .min(5, { message: 'register.email' }),
    password: passwordSchema,
    confirm_password: z
      .string()
      .min(8, { message: 'register.confirm_password' }),
    company_name: z.string(),
    cnpj: z.string(),
    company_fantasy_name: z.string(),
    company_address: z.string(),
    company_phone: z.string(),
    company_email: z.string(),
    captchaToken: z.string(),
  })
  .required()
  .partial({
    company_name: true,
    cnpj: true,
    company_fantasy_name: true,
    company_address: true,
    company_phone: true,
    company_email: true,
  })
  .refine(({ password, confirm_password }) => password === confirm_password, {
    message: 'register.confirm_password',
    path: ['confirm_password'],
  });

export type RegisterFormDataProps = z.infer<typeof registerFormSchema>;

export type RegisterFormPayload = Omit<
  z.infer<typeof registerFormSchema>,
  'captchaToken'
>;

export default function Register() {
  const t = useTranslations('Register');
  const tValidation = useTranslations('validation');
  const { signUp, openRegisterModal, openLoginModal } = useAuth();
  const selectedCar = localStorage.getItem(Tokens.MOBILITY_SELECTED_CAR);
  const selectedCarData = selectedCar ? JSON.parse(selectedCar) : null;

  const siteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? '';

  const recaptcha: RefObject<ReCAPTCHA> = useRef(null);

  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors },
    setValue,
  } = useForm<RegisterFormDataProps>({
    resolver: zodResolver(registerFormSchema),
    defaultValues: { user_type: 'person' },
  });

  const handleRecaptcha = (token) => {
    clearErrors('captchaToken');
    setValue('captchaToken', token);
  };

  const onSubmit: SubmitHandler<RegisterFormDataProps> = async (payload) => {
    try {
      const { captchaToken, ...data } = payload;
      if (payload.user_type === 'company') {
        let companyErrors = false;
        if (!payload.company_fantasy_name) {
          setError('company_fantasy_name', {
            type: 'required',
            message: 'required_field',
          });
          companyErrors = true;
        }
        if (!payload.captchaToken) {
          setError('captchaToken', {
            type: 'required',
            message: 'required_field',
          });
          companyErrors = true;
        }
        if (!payload.company_name) {
          setError('company_name', {
            type: 'required',
            message: 'required_field',
          });
          companyErrors = true;
        }
        if (!payload.cnpj) {
          setError('cnpj', {
            type: 'required',
            message: 'register.cnpj',
          });
          companyErrors = true;
        }
        if (!payload.company_address) {
          setError('company_address', {
            type: 'required',
            message: 'required_field',
          });
          companyErrors = true;
        }
        if (!payload.company_phone) {
          setError('company_phone', {
            type: 'required',
            message: 'required_field',
          });
          companyErrors = true;
        }
        if (!payload.company_email) {
          setError('company_email', {
            type: 'required',
            message: 'email',
          });
          companyErrors = true;
        }

        if (companyErrors) return;
      }

      await signUp(data as RegisterFormPayload);

      toast.success(t('success.message'), {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: 'light',
      });

      if (selectedCarData) {
        openLoginModal(true, '/checkout');
      } else {
        openLoginModal(true);
      }
    } catch (err) {
      const axiosError = err as AxiosError;
      console.log('erro', axiosError?.response);

      let errorMsg = 'account_already_exists';
      // if (axiosError && axiosError.response && axiosError.response.data) {
      //   const strapiError = axiosError.response?.data as {
      //     data: string | null;
      //     error: any;
      //     name: string;
      //     status: number;
      //   };
      //   if (
      //     strapiError.error &&
      //     strapiError.error.message === 'Invalid identifier or password'
      //   ) {
      //     errorMsg = 'validation.login.invalid_login_password';
      //   }
      // }

      toast.error(t(errorMsg), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const userTypeValue = watch('user_type');

  return (
    <div className={styles.register}>
      <h2 className="title title--large">{t('title')}</h2>

      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <div className="form__row form__row--full">
          <Controller
            control={control}
            name="user_type"
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                label={t('form.user_type.label')}
                placeholder={t('form.user_type.placeholder_person')}
                onValueChange={field.onChange}
                value={field.value?.toString()}
                options={['person', 'company'].map((userType) => ({
                  label: t(`form.user_type.placeholder_${userType}`),
                  value: userType,
                }))}
              />
            )}
          />
        </div>

        {userTypeValue === 'company' && (
          <>
            <div className="form__divider" />
            <div className="form__row">
              <Input
                id="company_fantasy_name"
                label={t('form.company_fantasy_name.label')}
                placeholder={t('form.company_fantasy_name.placeholder')}
                errors={errors?.company_fantasy_name?.message}
                {...register('company_fantasy_name', { required: true })}
              />
              <Input
                id="cnpj"
                label={t('form.cnpj.label')}
                placeholder={t('form.cnpj.placeholder')}
                errors={errors?.cnpj?.message}
                maxLength={18}
                {...register('cnpj', {
                  required: true,
                  maxLength: 18,
                  onChange: (e) =>
                    setValue('cnpj', applyCNPJMask(e.target.value)),
                })}
              />
            </div>
            <div className="form__row">
              <Input
                id="company_name"
                label={t('form.company_name.label')}
                placeholder={t('form.company_name.placeholder')}
                errors={errors?.company_name?.message}
                {...register('company_name', { required: true })}
              />
              <Input
                id="company_address"
                label={t('form.company_address.label')}
                placeholder={t('form.company_address.placeholder')}
                errors={errors?.company_address?.message}
                {...register('company_address', { required: true })}
              />
            </div>
            <div className="form__row">
              <Controller
                control={control}
                name="company_phone"
                rules={{ required: true }}
                render={({ field }) => (
                  <div>
                    <Label>{t('form.phone.label')}</Label>
                    <PhoneInput
                      flags={Flags}
                      defaultCountry="BR"
                      className={styles.phoneInput}
                      placeholder={t('form.phone.placeholder')}
                      value={field.value as E164Number}
                      onChange={field.onChange}
                    />
                  </div>
                )}
              />
              <Input
                id="company_email"
                label={t('form.email.label')}
                placeholder={t('form.email.placeholder')}
                errors={errors?.company_email?.message}
                {...register('company_email', { required: true })}
              />
            </div>
          </>
        )}

        <div className="form__divider" />

        <div className="form__row">
          <Input
            id="full_name"
            label={t('form.full_name.label')}
            errors={errors?.full_name?.message}
            {...register('full_name', { required: true })}
          />
          <Input
            id="cpf"
            label={t('form.cpf.label')}
            placeholder={t('form.cpf.placeholder')}
            errors={errors?.cpf?.message}
            maxLength={14}
            {...register('cpf', {
              required: true,
              maxLength: 14,
              onChange: (e) => setValue('cpf', applyCPFMask(e.target.value)),
            })}
          />
        </div>
        <div className="form__row">
          <Controller
            control={control}
            name="phone"
            rules={{ required: true }}
            render={({ field }) => (
              <div>
                <Label>{t('form.phone.label')}</Label>
                <PhoneInput
                  id="phone"
                  defaultCountry="BR"
                  flags={Flags}
                  className={`${errors?.phone?.message ? styles.error : styles.phoneInput}`}
                  placeholder={t('form.phone.placeholder')}
                  value={field.value as E164Number}
                  onChange={field.onChange}
                />
                {errors?.phone && (
                  <div className={styles['register__phone-error']}>
                    <Icon name="warning" style={{ color: '#ce2e2e' }} />
                    <p>{tValidation('register.phone')}</p>
                  </div>
                )}
              </div>
            )}
          />
          <Input
            id="email"
            label={t('form.email.label')}
            placeholder={t('form.email.placeholder')}
            errors={errors?.email?.message}
            {...register('email', { required: true })}
          />
        </div>

        <div className="form__divider" />

        <div className="form__row">
          <Input
            id="password"
            label={t('form.password.label')}
            type="password"
            placeholder={t('form.password.placeholder')}
            errors={errors?.password?.message}
            {...register('password', { required: true })}
          />
          <Input
            id="confirm_password"
            label={t('form.confirm_password.label')}
            type="password"
            placeholder={t('form.confirm_password.placeholder')}
            errors={errors?.confirm_password?.message}
            {...register('confirm_password', { required: true })}
          />
        </div>

        <div className={styles['register__recaptcha']}>
          <ReCAPTCHA
            onChange={handleRecaptcha}
            size="normal"
            sitekey={siteKey}
            ref={recaptcha}
          />

          {errors.captchaToken && (
            <p className={styles['register__recaptcha']}>
              {t('form.required')}
            </p>
          )}
        </div>

        <div className={styles['register__actions']}>
          <Button type="submit" variant="contained" color="secondary" full>
            {t('submit_button')}
          </Button>
          <Button
            onClick={() => openLoginModal(true)}
            variant="outlined"
            color="secondary"
            transform="initial"
            full
          >
            {t('login')}
          </Button>
        </div>
      </form>
    </div>
  );
}
