import VMasker from 'vanilla-masker';
import { localesTypes } from '@/config/locales';

export const cpfValidate = (cpf: string): boolean => {
  if (typeof cpf !== 'string') return false;
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
  const cpfDigits = cpf.split('').map((el) => +el);
  const rest = (count: number): number => {
    return (
      ((cpfDigits
        .slice(0, count - 12)
        .reduce((soma, el, index) => soma + el * (count - index), 0) *
        10) %
        11) %
      10
    );
  };
  return rest(10) === cpfDigits[9] && rest(11) === cpfDigits[10];
};

export const applyDOBMask = (value: string): string => {
  if (!value) return value;

  return VMasker.toPattern(value, '99/99/9999');
};

export const applyCNPJMask = (value: string): string => {
  if (!value) return value;

  return VMasker.toPattern(value, '99.999.999/9999-99');
};

export const applyZipCodeMask = (value: string): string => {
  if (!value) return value;
  const brMask = VMasker.toPattern(value, '99999-999');
  const zipMask = VMasker.toPattern(value, '99999-9999');

  if (value.length > 9) {
    return zipMask;
  }

  return brMask;
};

export const applyPhoneMask = (value: string): string => {
  if (!value) return value;

  return VMasker.toPattern(value, '+99 (99) 99999-9999');
};

export const applyCPFMask = (value: string): string => {
  if (!value) return value;

  return VMasker.toPattern(value, '999.999.999-99');
};

export const applyCreditCardNumberMask = (value: string): string => {
  if (!value) return value;

  return VMasker.toPattern(value, '9999 9999 9999 9999');
};

export const applyCreitCardValidateMask = (value: string): string => {
  if (!value) return value;

  return VMasker.toPattern(value, '99/9999');
};

export const removeMask = (str: string, chars: string): string => {
  const caracteresEscapados = chars.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(`[${caracteresEscapados}]`, 'g');

  const novaString = str.replace(regex, '');
  return novaString;
};

export const convertDobToISOFormat = (
  dob: string,
  locale: localesTypes,
): string => {
  const pieces = dob.split('/');
  let formatedDob = `${pieces[2]}-${pieces[0]}-${pieces[1]}`;
  if (locale === 'pt-br') {
    formatedDob = `${pieces[2]}-${pieces[1]}-${pieces[0]}`;
  }
  return formatedDob;
};

export const getInfoFromPhoneNumber = (
  value: string,
): {
  countryCode: string;
  areaCode: string;
  phoneNumber: string;
} => {
  let regexMobileNumber = /^\+(\d{1,1})(\d{3})(\d{4,})$/;

  if (value.includes('+55')) {
    regexMobileNumber = /^\+(\d{1,2})(\d{2})(\d{4,})$/;
  }

  const result = value.match(regexMobileNumber);

  if (!result) {
    return {
      countryCode: '',
      areaCode: '',
      phoneNumber: value,
    };
  }
  return {
    countryCode: result[1],
    areaCode: result[2],
    phoneNumber: result[3],
  };
};
