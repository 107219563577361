'use client';

import * as Dialog from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import Icon from '@/components/ui/Icon';

import styles from './Modal.module.scss';
import { useTranslations } from 'next-intl';

export interface ModalProps extends Dialog.DialogProps {
  children: React.ReactNode;
  className?: string;
  mobileFullscreen?: boolean;
  isLoading?: boolean;
}

const Modal = ({
  children,
  mobileFullscreen = false,
  className,
  isLoading,
  ...rest
}: ModalProps) => {
  const t = useTranslations('common');

  return (
    <Dialog.Root {...rest}>
      <Dialog.Overlay className={styles.DialogOverlay} />
      <Dialog.Content
        className={`${styles.DialogWrapper} ${className} ${
          mobileFullscreen && styles['DialogWrapper--mobile-full']
        }`}
      >
        <VisuallyHidden asChild>
          <Dialog.Title>
            <Dialog.Description>{t('open_modal')}</Dialog.Description>
          </Dialog.Title>
        </VisuallyHidden>
        <div
          className={`modal-content ${styles.DialogContent} ${
            mobileFullscreen && styles['DialogContent--mobile-full']
          }`}
        >
          <Dialog.Close asChild>
            <button
              className={styles.IconButton}
              aria-label="Close"
              disabled={isLoading}
            >
              <Icon name="close" />
            </button>
          </Dialog.Close>
          {children}
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default Modal;
