'use client';

import { AxiosError } from 'axios';
import { useTranslations } from 'next-intl';
import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import Input from '@/components/form/Input';
import Button from '@/components/ui/Button';

import styles from './ForgotPassword.module.scss';
import { useEffect, useState } from 'react';
import { strapiApi } from '@/services/api';
import { useAuth } from '@/contexts/AuthContext';

const emailFormSchema = z
  .object({
    email: z.string().email({ message: 'required_field' }),
  })
  .required();

const passwordFormSchema = z
  .object({
    token: z.string().min(8, { message: 'required_field' }),
    newPassword: z.string().min(8, { message: 'required_field' }),
    confirmPassword: z.string().min(8, { message: 'required_field' }),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: 'forgot_password.password_doesnt_match',
    path: ['confirmPassword'],
  });

export type EmailFormDataProps = z.infer<typeof emailFormSchema>;
export type PasswordFormDataProps = z.infer<typeof passwordFormSchema>;

export type LoginFormDataPayload = Omit<
  z.infer<typeof emailFormSchema>,
  'captchaToken'
>;

export default function ForgotPassword() {
  const t = useTranslations();
  const [emailVerified, setEmailVerified] = useState(false);
  const { openForgotPasswordModal } = useAuth();

  const {
    register: registerEmail,
    handleSubmit: handleSubmitEmail,
    setError: setErrorEmail,
    formState: { errors: errorsEmail },
  } = useForm<EmailFormDataProps>({
    resolver: zodResolver(emailFormSchema),
  });

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    setValue: setValuePassword,
    clearErrors: clearErrorsPassword,
    setError: setErrorPassword,
    reset: resetPasswordForm,
    formState: { errors: errorsPassword },
  } = useForm<PasswordFormDataProps>({
    resolver: zodResolver(passwordFormSchema),
  });

  const onSubmitEmail: SubmitHandler<EmailFormDataProps> = async (payload) => {
    try {
      const response = await strapiApi.post('/auth/forgot-password', {
        email: payload.email,
      });

      if (response.status === 200) {
        setEmailVerified(true);
      } else {
        setErrorEmail('email', {
          message: 'validation.forgot_password.email_error',
        });
        toast.error(t('validation.forgot_password.email_error'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      const axiosError = err as AxiosError;
      let errorMsg = 'validation.generic_error';

      if (axiosError.response?.status === 500) {
        errorMsg = 'validation.forgot_password.password_error';
      }

      toast.error(t(errorMsg), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const onSubmitPassword: SubmitHandler<PasswordFormDataProps> = async (
    payload,
  ) => {
    try {
      const response = await strapiApi.post('/auth/reset-password', {
        code: payload.token,
        password: payload.newPassword,
        passwordConfirmation: payload.confirmPassword,
      });

      if (response.status === 200) {
        toast.success(t('validation.forgot_password.password_success'), {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: 'light',
        });
        openForgotPasswordModal(false);
      }
    } catch (err) {
      const axiosError = err as AxiosError;
      let errorMsg = 'validation.generic_error';

      if (axiosError.response?.status === 500) {
        errorMsg = 'validation.forgot_password.password_error';
      }

      toast.error(t(errorMsg), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const handleCancel = () => {
    if (emailVerified) {
      setEmailVerified(false);
    }
  };

  useEffect(() => {
    if (emailVerified) {
      resetPasswordForm();
    }
  }, [emailVerified, resetPasswordForm]);

  return (
    <div className={styles.login}>
      <h2 className="title title--large">{t('ForgotPassword.title')}</h2>

      {!emailVerified ? (
        <form
          onSubmit={handleSubmitEmail(onSubmitEmail)}
          className={styles['login__form']}
        >
          <Input
            id="email"
            label={t('ForgotPassword.email_label')}
            placeholder={t('ForgotPassword.email_placeholder')}
            errors={errorsEmail?.email?.message}
            {...registerEmail('email', { required: true })}
          />

          <div className={styles['login__actions']}>
            <Button type="submit" variant="contained" color="secondary" full>
              {t('ForgotPassword.email_button_next')}
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              full
              onClick={handleCancel}
            >
              {t('ForgotPassword.email_button_cancel')}
            </Button>
          </div>
        </form>
      ) : (
        <form
          onSubmit={handleSubmitPassword(onSubmitPassword)}
          className={styles['login__form']}
        >
          <Input
            id="token"
            label={t('ForgotPassword.token_field_label')}
            placeholder={t('ForgotPassword.token_field_placeholder')}
            type="password"
            errors={errorsPassword?.token?.message}
            {...registerPassword('token', { required: true })}
          />

          <Input
            id="newPassword"
            label={t('ForgotPassword.new_password_label')}
            placeholder={t('ForgotPassword.new_password_placeholder')}
            type="password"
            errors={errorsPassword?.newPassword?.message}
            {...registerPassword('newPassword', { required: true })}
          />
          <Input
            id="confirmPassword"
            label={t('ForgotPassword.confirm_password_label')}
            placeholder={t('ForgotPassword.confirm_password_placeholder')}
            type="password"
            errors={errorsPassword?.confirmPassword?.message}
            {...registerPassword('confirmPassword', { required: true })}
          />

          <div className={styles['login__actions']}>
            <Button type="submit" variant="contained" color="secondary" full>
              {t('ForgotPassword.password_button_confirm')}
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              full
              onClick={handleCancel}
            >
              {t('ForgotPassword.password_button_back')}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
}
