import { ActionMap } from '@/utils/contexts';
import { RegisterFormDataProps } from '@/templates/Register';
import { AxiosResponse } from 'axios';

export interface SignInPayload {
  identifier: string | FormDataEntryValue | null;
  password: string | FormDataEntryValue | null;
}

export interface StateProps {
  loading: boolean;
  isLogged: boolean;
  isLoginModalOpen: boolean;
  forgotPassword: {
    openModal: boolean;
  };
  session: any;
  login: {
    redirectTo?: string;
    openModal: boolean;
  };
  register: {
    openModal: boolean;
  };
}

export enum ActionTypes {
  Loading = 'LOADING',
  ToggleLogin = 'TOGGLE_LOGIN',
  ToggleForgotPassword = 'TOGGLE_FORGOT_PASSWORD',
  ToggleRegister = 'TOGGLE_REGISTER',
  SetSession = 'SET_SESSION',
}
export type ActionPayload = {
  [ActionTypes.Loading]: {
    loading: boolean;
  };
  [ActionTypes.ToggleLogin]: {
    open: boolean;
    redirectTo?: string;
  };
  [ActionTypes.ToggleForgotPassword]: {
    open: boolean;
    redirectTo?: string;
  };
  [ActionTypes.ToggleRegister]: {
    open: boolean;
    redirectTo?: string;
  };
  [ActionTypes.SetSession]: {
    isLogged: boolean;
    session: any;
  };
};

export type ReducerActions =
  ActionMap<ActionPayload>[keyof ActionMap<ActionPayload>];

export interface ContextProps extends StateProps {
  openLoginModal: (open: boolean, redirectTo?: string) => void;
  openForgotPasswordModal: (open: boolean) => void;
  openRegisterModal: (open: boolean, redirectTo?: string) => void;
  signIn: (payload: SignInPayload) => Promise<boolean>;
  signUp: (
    payload: Omit<RegisterFormDataProps, 'captchaToken'>,
  ) => Promise<AxiosResponse<any, any>>;
}
