import classNames from 'classnames';

import styles from './Label.module.scss';

export interface LabelProps {
  children: React.ReactNode;
  as?: keyof HTMLElementTagNameMap;
  size?: 'normal' | 'large';
  weight?: '400' | '500' | '600' | '700';
  color?: 'primary' | 'secondary';
  htmlFor?: string;
}

const Label = ({
  children,
  htmlFor,
  as = 'label',
  size = 'normal',
  weight = '500',
  color = 'primary',
}: LabelProps) => {
  const El = as;
  const classes = classNames(
    'form-label',
    styles.label,
    styles[`label--${size}`],
    styles[`label--weight-${weight}`],
    styles[`label--${color}`],
  );

  return (
    <El className={classes} {...(htmlFor ? { htmlFor: htmlFor } : {})}>
      {children}
    </El>
  );
};

export default Label;
