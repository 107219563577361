import Login from '@/templates/Login';
import Modal, { ModalProps } from '@/components/ui/Modal';

import styles from './LoginModal.module.scss';
import { useAuth } from '@/contexts/AuthContext';

interface LoginModalProps extends Omit<ModalProps, 'children'> {}

const LoginModal = ({ ...rest }: LoginModalProps) => {
  const { loading } = useAuth();

  return (
    <Modal className={styles.loginModal} isLoading={loading} {...rest}>
      <Login />
    </Modal>
  );
};

export default LoginModal;
