'use client';

import { AxiosError } from 'axios';
import { useTranslations } from 'next-intl';
import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useAuth } from '@/contexts/AuthContext';

import Input from '@/components/form/Input';
import Button from '@/components/ui/Button';

import styles from './Login.module.scss';
import ReCAPTCHA from 'react-google-recaptcha';
import { RefObject, useRef } from 'react';

const loginFormSchema = z
  .object({
    identifier: z.string().min(2, { message: 'email' }),
    password: z.string().min(2, { message: 'password' }),
    captchaToken: z.string({ required_error: 'required_field' }),
  })
  .required();

export type LoginFormDataProps = z.infer<typeof loginFormSchema>;

export type LoginFormDataPayload = Omit<
  z.infer<typeof loginFormSchema>,
  'captchaToken'
>;

export default function Login() {
  const t = useTranslations();
  const { signIn, openRegisterModal, openForgotPasswordModal } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<LoginFormDataProps>({
    resolver: zodResolver(loginFormSchema),
  });

  const siteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? '';

  const recaptcha: RefObject<ReCAPTCHA> = useRef(null);

  const handleRecaptcha = (token) => {
    clearErrors('captchaToken');
    setValue('captchaToken', token);
  };

  const onSubmit: SubmitHandler<LoginFormDataProps> = async (payload) => {
    try {
      const { captchaToken, ...data } = payload;
      if (!captchaToken) {
        setError('captchaToken', {
          type: 'required',
          message: 'required_field',
        });

        return;
      }

      await signIn(data as LoginFormDataPayload);

      toast.success(t('validation.login.success'), {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: 'light',
      });
    } catch (err) {
      const axiosError = err as AxiosError;
      let errorMsg = 'validation.login.invalid_login_password';

      if (axiosError.response?.status === 500) {
        errorMsg = 'validation.generic_error';
      }

      if (axiosError && axiosError.response && axiosError.response.data) {
        const strapiError = axiosError.response?.data as {
          data: string | null;
          error: any;
          name: string;
          status: number;
        };
        if (
          strapiError.error &&
          strapiError.error.message === 'Invalid identifier or password'
        ) {
          errorMsg = 'validation.login.invalid_login_password';
        }
      }

      toast.error(t(errorMsg), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  return (
    <div className={styles.login}>
      <h2 className="title title--large">{t('Login.title')}</h2>

      <form onSubmit={handleSubmit(onSubmit)} className={styles['login__form']}>
        <Input
          id="identifier"
          label={t('Login.email.label')}
          placeholder={t('Login.email.placeholder')}
          errors={errors?.identifier?.message}
          {...register('identifier', { required: true })}
        />
        <Input
          id="password"
          label={t('Login.password.label')}
          type="password"
          placeholder="digite sua senha"
          errors={errors?.password?.message}
          {...register('password', { required: true })}
        />

        <div>
          <Button
            onClick={() => openForgotPasswordModal(true)}
            variant="text"
            color="primary"
            size="fit"
            transform="initial"
          >
            {t('Login.forgot_password')}
          </Button>
        </div>

        <div className={styles['login__recaptcha']}>
          <ReCAPTCHA
            onChange={handleRecaptcha}
            size="normal"
            sitekey={siteKey}
            ref={recaptcha}
          />

          {errors.captchaToken && (
            <p className={styles['login__recaptcha']}>
              {t('Register.form.required')}
            </p>
          )}
        </div>

        <div className={styles['login__actions']}>
          <Button type="submit" variant="contained" color="secondary" full>
            {t('Login.submit_button')}
          </Button>
          <Button
            onClick={() => openRegisterModal(true)}
            variant="outlined"
            color="secondary"
            transform="initial"
            full
          >
            {t('Login.create_account')}
          </Button>
        </div>
      </form>
    </div>
  );
}
