import Modal, { ModalProps } from '@/components/ui/Modal';

import styles from './ForgotPasswordModal.module.scss';
import ForgotPassword from '@/templates/ForgotPassword';

interface ForgotPasswordModalProps extends Omit<ModalProps, 'children'> {}

const ForgotPasswordModal = ({ ...rest }: ForgotPasswordModalProps) => {
  return (
    <Modal className={styles.loginModal} {...rest}>
      <ForgotPassword />
    </Modal>
  );
};

export default ForgotPasswordModal;
