'use client';

import * as SelectRadix from '@radix-ui/react-select';
import classNames from 'classnames/bind';
import { useTranslations } from 'next-intl';

import Label, { LabelProps } from '@/components/form/Label';
import Icon from '@/components/ui/Icon';

import styles from './Select.module.scss';

export interface SelectProps extends SelectRadix.SelectProps {
  placeholder: string;
  label?: string;
  labelProps?: Omit<LabelProps, 'children'>;
  id?: string;
  elSize?: 'normal' | 'large';
  color?: 'primary' | 'alternative';
  errors?: string | null;
  options: {
    label: string;
    value: string;
    disabled?: boolean;
  }[];
}

const cx = classNames.bind(styles);

const Select = ({
  id,
  placeholder,
  label,
  labelProps,
  color = 'primary',
  elSize = 'normal',
  options = [],
  errors,
  ...rest
}: SelectProps) => {
  const t = useTranslations('validation');

  const hasError = Boolean(!!errors);
  const triggerClasses = cx(
    'trigger',
    `trigger--${color}`,
    `trigger--${elSize}`,
    {
      'trigger--error': hasError,
    },
  );

  return (
    <div className={styles.selectContainer}>
      {label && (
        <Label htmlFor={id} {...(labelProps ? labelProps : {})}>
          {label}
        </Label>
      )}
      <SelectRadix.Root {...rest}>
        <SelectRadix.Trigger className={triggerClasses} aria-label="Food">
          <SelectRadix.Value placeholder={placeholder} />
          <SelectRadix.Icon className={styles['trigger__icon']}>
            <Icon name="expand_more" />
          </SelectRadix.Icon>
        </SelectRadix.Trigger>

        <SelectRadix.Content
          className={styles['select__content']}
          position="popper"
          sideOffset={5}
        >
          <SelectRadix.ScrollUpButton
            className={styles['select__scroll-button']}
          >
            <Icon name="expand_less" />
          </SelectRadix.ScrollUpButton>
          <SelectRadix.Viewport className={styles['select__viewport']}>
            {options.map((opt) => (
              <SelectRadix.Item
                key={`select-option-${opt.value}`}
                className={styles['select__item']}
                value={opt.value}
                disabled={opt.disabled}
              >
                <SelectRadix.ItemText>{opt.label}</SelectRadix.ItemText>
              </SelectRadix.Item>
            ))}
          </SelectRadix.Viewport>
          <SelectRadix.ScrollDownButton
            className={styles['select__scroll-button']}
          >
            <Icon name="expand_more" />
          </SelectRadix.ScrollDownButton>
        </SelectRadix.Content>
      </SelectRadix.Root>

      {hasError && (
        <div className={styles.error}>
          <Icon name="warning" />
          <span className={styles['error__text']}>
            {errors ? t(errors) : ''}
          </span>
        </div>
      )}
    </div>
  );
};

export default Select;
