'use client';

import { InputHTMLAttributes, forwardRef } from 'react';
import classNames from 'classnames/bind';
import { useTranslations } from 'next-intl';

import Label, { LabelProps } from '@/components/form/Label';
import Icon from '@/components/ui/Icon';

import styles from './Input.module.scss';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelProps?: Omit<LabelProps, 'children'>;
  id?: string;
  elSize?: 'normal' | 'large';
  color?: 'primary' | 'alternative';
  iconStart?: string;
  iconEnd?: string;
  customIcon?: boolean;
  errors?: string | null;
  readOnly?: boolean;
  maxLength?: number;
}

type Ref = HTMLInputElement;

const cx = classNames.bind(styles);

const Input = forwardRef<Ref, InputProps>(
  (
    {
      label,
      labelProps,
      id = '',
      elSize = 'normal',
      color = 'primary',
      iconStart,
      iconEnd,
      customIcon = false,
      readOnly = false,
      errors,
      ...rest
    },
    ref,
  ) => {
    const t = useTranslations('validation');

    const hasError = Boolean(!!errors);
    const inputWrapperClasses = cx('inputWrapper', `inputWrapper--${elSize}`, {
      'inputWrapper--start-icon': Boolean(iconStart),
      'inputWrapper--end-icon': Boolean(iconEnd),
      'inputWrapper--custom-icon': customIcon,
      'inputWrapper--error': hasError,
    });
    const inputClasses = cx('input', `input--${elSize}`, `input--${color}`, {
      'input--start-icon': Boolean(iconStart),
      'input--end-icon': Boolean(iconEnd),
      'input--error': hasError,
      'input--readonly': readOnly,
    });

    return (
      <div className={`form-input ${styles.inputContainer}`}>
        {label && (
          <Label htmlFor={id} {...(labelProps ? labelProps : {})}>
            {label}
          </Label>
        )}
        <div className={inputWrapperClasses}>
          {iconStart && <Icon name={iconStart} />}
          <input id={id} ref={ref} {...rest} className={inputClasses} />
          {iconEnd && <Icon name={iconEnd} />}
        </div>

        {hasError && (
          <div className={styles.error}>
            <Icon name="warning" />
            <span className={styles['error__text']}>
              {errors ? t(errors) : ''}
            </span>
          </div>
        )}
      </div>
    );
  },
);

export default Input;
