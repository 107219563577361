import React from 'react';

import { BeatLoader } from 'react-spinners';

import styles from './Backdrop.module.scss';

export default function Backdrop() {
  return (
    <div className={styles.container}>
      <div className={styles.spinner}>
        <BeatLoader color="#ffbc6d" />
      </div>
    </div>
  );
}
