import Register from '@/templates/Register';
import Modal, { ModalProps } from '@/components/ui/Modal';

import styles from './RegisterModal.module.scss';

interface RegisterModalProps extends Omit<ModalProps, 'children'> {}

const RegisterModal = ({ ...rest }: RegisterModalProps) => {
  return (
    <Modal className={styles.registerModal} {...rest}>
      <Register />
    </Modal>
  );
};

export default RegisterModal;
