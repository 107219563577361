import axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';

const baseURL = `${process.env.NEXT_PUBLIC_STRAPI_CMS_URL}/api`;
// const baseURL = 'http://localhost:1337/api';

export const strapiApi = axios.create({
  baseURL: baseURL,
  headers: {
    // 'Authorization': `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_TOKEN}`,
    'Content-Type': 'application/json',
  },
});

strapiApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export const mobilityApi = axios.create({
  baseURL: `${process.env.MOBILITY_API_DOMAIN_URL}/api/`,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const mobilityApiCahed = setupCache(mobilityApi, {
  ttl: 1000 * 60 * 5, // 5 minutes
  methods: ['post'],
  cachePredicate: {
    statusCheck(status) {
      return status === 200;
    },
  },
});
