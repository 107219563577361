'use client';

import {
  createContext,
  useMemo,
  useReducer,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSession, signIn } from 'next-auth/react';

import LoginModal from '@/components/ui/LoginModal';
import RegisterModal from '@/components/ui/RegisterModal';
import { strapiApi } from '@/services/api';
import { RegisterFormDataProps } from '@/templates/Register';

import {
  StateProps,
  ActionTypes,
  SignInPayload,
  ReducerActions,
  ContextProps,
} from './types';
import { useRouter } from 'next/navigation';
import ForgotPasswordModal from '@/components/ui/ForgotPasswordModal';
import Backdrop from '@/components/ui/Backdrop';
import { reportErrorToNewRelic } from '@/utils/reportError';
import { POST } from '@/app/api/proxy/auth/route';

const initialState: StateProps = {
  loading: false,
  isLogged: false,
  isLoginModalOpen: false,
  forgotPassword: {
    openModal: false,
  },
  session: null,
  login: {
    openModal: false,
  },
  register: {
    openModal: false,
  },
};

const fetchReducer = (state: StateProps, action: ReducerActions) => {
  switch (action.type) {
    case ActionTypes.Loading:
      return { ...state, loading: action.payload.loading };
    case ActionTypes.SetSession:
      return {
        ...state,
        isLogged: action.payload.isLogged,
        session: action.payload.session,
      };
    case ActionTypes.ToggleLogin:
      return {
        ...state,
        register: {
          openModal: false,
        },
        login: {
          openModal: action.payload.open,
          redirectTo: action.payload.redirectTo,
        },
      };
    case ActionTypes.ToggleForgotPassword:
      return {
        ...state,
        login: {
          openModal: false,
        },
        forgotPassword: {
          openModal: action.payload.open,
        },
      };
    case ActionTypes.ToggleRegister:
      return {
        ...state,
        login: {
          openModal: false,
        },
        register: {
          openModal: action.payload.open,
        },
      };
    default:
      return state;
  }
};

export const AuthContext = createContext<ContextProps>({} as ContextProps);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { data: session, status } = useSession();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] =
    useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const router = useRouter();
  const [state, dispatch] = useReducer<
    React.Reducer<StateProps, ReducerActions>
  >(fetchReducer, { ...initialState, isLogged: !!session, session });

  // const handleSignIn = useCallback(
  //   async (payload: SignInPayload) => {
  //     dispatch({
  //       type: ActionTypes.Loading,
  //       payload: { loading: true },
  //     });

  //     return new Promise(async (resolve, reject) => {
  //       try {
  //         const { data } = await strapiApi.post('/auth/local', {
  //           identifier: payload.identifier,
  //           password: payload.password,
  //         });

  //         await signIn('credentials', {
  //           email: payload.identifier,
  //           password: payload.password,
  //           redirect: !!state.login.redirectTo,
  //           callbackUrl: state.login.redirectTo,
  //         });
  //         dispatch({
  //           type: ActionTypes.ToggleLogin,
  //           payload: {
  //             open: false,
  //           },
  //         });
  //         setBackdrop(false);
  //         resolve(data);
  //       } catch (error) {
  //         // reportErrorToNewRelic(error);
  //         reject(error);
  //       } finally {
  //         dispatch({
  //           type: ActionTypes.Loading,
  //           payload: { loading: false },
  //         });
  //       }
  //     });
  //   },
  //   [state],
  // );
  const handleSignIn = useCallback(
    async (payload: SignInPayload) => {
      dispatch({
        type: ActionTypes.Loading,
        payload: { loading: true },
      });

      return new Promise(async (resolve, reject) => {
        try {
          const data = await fetch('/api/proxy/auth', {
            method: 'POST',
            body: JSON.stringify({
              email: payload.identifier,
              password: payload.password,
            }),
          });

          if (data.status === 200) {
            const result = await signIn('credentials', {
              email: payload.identifier,
              password: payload.password,
              redirect: false,
            });

            if (result?.ok) {
              const redirectPath = state.login.redirectTo || '/';
              router.push(redirectPath);
              dispatch({
                type: ActionTypes.ToggleLogin,
                payload: {
                  open: false,
                },
              });
              setBackdrop(false);
              resolve(data);
            } else {
              reject(new Error('Login failed'));
            }
          } else {
            reject(new Error('Login failed'));
          }
        } catch (error) {
          reject(error);
        } finally {
          dispatch({
            type: ActionTypes.Loading,
            payload: { loading: false },
          });
        }
      });
    },
    [state.login.redirectTo, router],
  );

  const handleSignUp = useCallback((payload: RegisterFormDataProps) => {
    setBackdrop(false);

    return fetch('/api/proxy/auth', {
      method: 'POST',
      body: JSON.stringify({
        payload,
      }),
    });
  }, []);

  const openLoginModal = useCallback((open: boolean, redirectTo?: string) => {
    setIsLoginModalOpen(open);
    setBackdrop(open);
    dispatch({
      type: ActionTypes.ToggleLogin,
      payload: {
        open,
        redirectTo,
      },
    });
  }, []);

  const openRegisterModal = useCallback(
    (open: boolean, redirectTo?: string) => {
      setIsRegisterModalOpen(open);
      setBackdrop(open);
      dispatch({
        type: ActionTypes.ToggleRegister,
        payload: {
          open,
          redirectTo,
        },
      });
    },
    [],
  );

  const openForgotPasswordModal = useCallback(
    (open: boolean, redirectTo?: string) => {
      setIsForgotPasswordModalOpen(open);
      setBackdrop(open);
      dispatch({
        type: ActionTypes.ToggleForgotPassword,
        payload: {
          open,
          redirectTo,
        },
      });
    },
    [],
  );

  useEffect(() => {
    dispatch({
      type: ActionTypes.SetSession,
      payload: {
        isLogged: !!session,
        session,
      },
    });
  }, [session]);

  const values = useMemo(
    () => ({
      ...state,
      isLoginModalOpen,
      setisLoginModalOpen: setIsLoginModalOpen,
      openLoginModal,
      openRegisterModal,
      openForgotPasswordModal,
      signIn: handleSignIn,
      signUp: handleSignUp,
    }),
    [
      state,
      openLoginModal,
      openForgotPasswordModal,
      openRegisterModal,
      handleSignIn,
      handleSignUp,
      isLoginModalOpen,
      setIsLoginModalOpen,
    ],
  );

  return (
    <AuthContext.Provider value={values}>
      {children}
      <LoginModal
        open={state.login.openModal}
        onOpenChange={() => openLoginModal(!state.login.openModal)}
      />
      <RegisterModal
        open={state.register.openModal}
        onOpenChange={() => openRegisterModal(!state.register.openModal)}
      />
      <ForgotPasswordModal
        open={state.forgotPassword.openModal}
        onOpenChange={() =>
          openForgotPasswordModal(!state.forgotPassword.openModal)
        }
      />

      {backdrop && <Backdrop />}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthContext');
  }
  return context;
}
